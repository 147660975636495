
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import store from '@/store';

@Options({})
export default class domandaRompighiaccio extends Vue {

    @Prop() readonly: boolean;
    @Prop() message: OM.ChatMessage;
    @Prop() otherName: string;
    @Prop() primaryName: string;
    @Prop() primaryIdentifier: string;
    @Prop({
        default: 'right'
    }) position: 'right' | 'left';
    @Prop() time: string;

    isSelf: boolean = false;

    created() {
        this.isSelf = this.primaryIdentifier == this.message.senderIdentifier;
    }

    get yourAnswer(){
        return this.message.answers.find(x => x.senderIdentifier == this.primaryIdentifier);
    }

    get otherAnswer(){
        return this.message.answers.find(x => x.senderIdentifier != this.primaryIdentifier);
    }

    get canSeeOtherAnswer(){
        return !!this.yourAnswer;
    }

}
